import React, { useState, useEffect } from 'react';
import './printerdata.scss';
import '../topbar/topbar.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';

import { Link } from 'react-router-dom';

const Printerdata = () => {
  const [printers, setPrinters] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchPrinters = async () => {
      try {
        const storedToken = localStorage.getItem('accessToken');
        const response = await fetch(`${apiUrl}/printers`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch printers');
        }
        const data = await response.json();
        setPrinters(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchPrinters();
  }, []);

  return (
    <><div className="navbar">
    <div className="wrapper">
      {/* <div className="search">
        <input type="text" placeholder="Search..." />
        <SearchOutlinedIcon className="icon" />
      </div> */}
      <div className="items">
        <div className="item">
          <Link to="/printers/addPrinter" style={{ textDecoration: 'none' }}>
            <AddIcon className="icon" />
          </Link>
        </div>
        {/* <div className="item">
          <DownloadIcon className="icon" />
        </div> */}
      </div>
    </div>
  </div>
    <TableContainer component={Paper} className="reporttable">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell">Printer Name</TableCell>
            <TableCell className="tableCell">IP Address</TableCell>
            <TableCell className="tableCell">Port</TableCell>
            <TableCell className="tableCell">Location</TableCell>
            <TableCell className="tableCell">printer Type</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {printers.map((printer) => (
            <TableRow key={printer._id}>
              <TableCell>{printer.printerName}</TableCell>
              <TableCell className="tableCell">{printer.ipAddress}</TableCell>
              <TableCell className="tableCell">{printer.port}</TableCell>
              <TableCell className="tableCell">{printer.location}</TableCell>
              <TableCell className="tableCell">{printer.printerType}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer></>
  );
};

export default Printerdata;
