import React, { useState } from 'react';
import './register.scss';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { Link } from 'react-router-dom';
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from "@aws-sdk/client-s3";

const Register = () => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const uploadToS3 = async (file) => {
    try {
      const target = { Bucket: "ttt-media", Key: `marchant/${file.name}`, Body: file };
      const creds = {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      };

      const parallelUploadS3 = new Upload({
        client: new S3Client({ region: process.env.REACT_APP_AWS_REGION, credentials: creds }),
        params: target,
      });

      parallelUploadS3.on("httpUploadProgress", (progress) => {
        console.log(progress);
      });

      await parallelUploadS3.done();

      setPreviewUrl(`https://ttt-media.s3.ap-southeast-1.amazonaws.com/marchant/${file.name}`);
      setUploadStatus('Upload successful');
    } catch (e) {
      setUploadStatus(`Upload failed: ${e.message}`);
      console.log(e);
    }
  };

  const [formData, setFormData] = useState({
    username: '',
    email: '',
    address: '',
    phone: '',
    password: '',
    ssmno: '',
    icImage: '',
    ssmImage: '',
    bankstatement: '',
  });

  // State variables for different file types
  const [icFile, setIcFile] = useState(null);
  const [ssmFile, setSsmFile] = useState(null);
  const [bankStatementFile, setBankStatementFile] = useState(null);

  // State variables for different preview URLs
  const [icPreviewUrl, setIcPreviewUrl] = useState('');
  const [ssmPreviewUrl, setSsmPreviewUrl] = useState('');
  const [bankStatementPreviewUrl, setBankStatementPreviewUrl] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e, fileType) => {
    const selectedFile = e.target.files[0];

    // Set the appropriate file state based on the fileType
    switch (fileType) {
      case 'ic':
        setIcFile(selectedFile);
        setIcPreviewUrl(selectedFile ? URL.createObjectURL(selectedFile) : '');
        break;
      case 'ssm':
        setSsmFile(selectedFile);
        setSsmPreviewUrl(selectedFile ? URL.createObjectURL(selectedFile) : '');
        break;
      case 'bankStatement':
        setBankStatementFile(selectedFile);
        setBankStatementPreviewUrl(selectedFile ? URL.createObjectURL(selectedFile) : '');
        break;
      default:
        break;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Upload each file type to S3 separately
      if (icFile) {
        await uploadToS3(icFile);
      }
      if (ssmFile) {
        await uploadToS3(ssmFile);
      }
      if (bankStatementFile) {
        await uploadToS3(bankStatementFile);
      }

      const apiData = {
        username: formData.username,
        email: formData.email,
        name: formData.name,
        address: formData.address,
        phone: formData.phone,
        password: formData.password,
        ssmno: formData.ssmno,
        icImage: icFile ? `https://ttt-media.s3.ap-southeast-1.amazonaws.com/marchant/${icFile.name}` : '',
        ssmImage: ssmFile ? `https://ttt-media.s3.ap-southeast-1.amazonaws.com/marchant/${ssmFile.name}` : '',
        bankstatement: bankStatementFile ? `https://ttt-media.s3.ap-southeast-1.amazonaws.com/marchant/${bankStatementFile.name}` : '',
      };
      console.log('Data sent to API:', apiData);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/merchants`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(apiData),
      });

      if (response.ok) {
        setSuccessMessage('Merchant created successfully');
        setErrorMessage('');
        console.log('Merchant created successfully:', await response.json());
      } else {
        const errorData = await response.json();
        setSuccessMessage('');
        setErrorMessage(`Error creating Merchant: ${errorData.message}`);
        console.error('Error creating Merchant:', errorData.message);
      }
    } catch (error) {
      setSuccessMessage('');
      setErrorMessage(`Error: ${error.message}`);
      console.error('Error:', error);
    }
  };

  return (
    <div className="register">
      <div className="newContainer">
        <div className="top">
          <h1>Register Merchant</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <form onSubmit={handleSubmit}>
              <div className="formInput">
                <label> Merchant Name</label>
                <input type="text" name="username" placeholder="Merchant Name" value={formData.username} onChange={handleChange} />
              </div>
              
              <div className="formInput">
                <label> Email </label>
                <input type="text" name="email" placeholder="Email" value={formData.email} onChange={handleChange} />
              </div>
              <div className="formInput">
                <label> Password </label>
                <input type="password" name="password" placeholder="Password" value={formData.password} onChange={handleChange} />
              </div>
              <div className="formInput">
                <label> Address </label>
                <input type="text" name="address" placeholder="15/1 Jalan PJU 54201 PJ, Selangor" value={formData.address} onChange={handleChange} />
              </div>
              <div className="formInput">
                <label> Phone </label>
                <input type="text" name="phone" placeholder="6011 2836 2587" value={formData.phone} onChange={handleChange} />
              </div>
              <div className="formInput">
                <label> SSM Number </label>
                <input type="text" name="ssmno" placeholder="1451121-A" value={formData.ssmno} onChange={handleChange} />
              </div>
              <div className="formInput">
                <label htmlFor="icFile"> IC Upload <DriveFolderUploadIcon className="icon" /> </label>
                <input type="file" id="icFile" style={{ display: 'none' }} onChange={(e) => handleFileChange(e, 'ic')} />
                {icFile && <img src={icPreviewUrl} alt="IC Preview" style={{ maxWidth: '15%', marginTop: '1px' }} />}
                <span>{uploadStatus}</span>
              </div>
              <div className="formInput">
                <label htmlFor="ssmFile"> SSM upload <DriveFolderUploadIcon className="icon" /> </label>
                <input type="file" id="ssmFile" style={{ display: 'none' }} onChange={(e) => handleFileChange(e, 'ssm')} />
                {ssmFile && <img src={ssmPreviewUrl} alt="SSM Preview" style={{ maxWidth: '15%', marginTop: '1px' }} />}
                <span>{uploadStatus}</span>
              </div>
              <div className="formInput">
                <label htmlFor="bankStatementFile"> Bank Statement <DriveFolderUploadIcon className="icon" /> </label>
                <input
                  type="file"
                  id="bankStatementFile"
                  style={{ display: 'none' }}
                  onChange={(e) => handleFileChange(e, 'bankStatement')}
                />
                {bankStatementFile && (
                  <img
                    src={bankStatementPreviewUrl}
                    alt="Bank Statement Preview"
                    style={{ maxWidth: '15%', marginTop: '1px' }}
                  />
                )}
                <span>{uploadStatus}</span>
              </div>
              <div className="formInput">
                <button type="submit">Submit</button>
              </div>
              <div className="formInput">
                <span style={{ color: 'green' }}>{successMessage}</span>
                <span style={{ color: 'red' }}>{errorMessage}</span>
                <span> You already have an account. <Link to="/login" style={{ textDecoration: 'none' }}>Login</Link></span>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
