import React, { useState, useEffect } from 'react';
import './financedata.scss';
import '../topbar/topbar.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';

const Financedata = () => {
  const [finances, setFinances] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); 
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchFinances = async () => {
      try {
        const response = await fetch(`${apiUrl}/finances`);
        if (!response.ok) {
          throw new Error('Failed to fetch finance data');
        }
        const data = await response.json();
        setFinances(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchFinances();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <div className="navbar">
        <div className="wrapper">
          <div className="items">
            <div className="item">
              <Link to="/finances/addFinance" style={{ textDecoration: 'none' }}>
                <AddIcon className="icon" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <TableContainer component={Paper} className="financetable">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableCell">Merchant ID</TableCell>
              <TableCell className="tableCell">Type</TableCell>
              <TableCell className="tableCell">Total Calculated Value</TableCell>
              <TableCell className="tableCell">Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? finances.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : finances
            ).map((finance) => (
              <TableRow key={finance._id} className={finance.type === 'opening' ? 'opening' : 'closing'}>
                <TableCell>{finance.merchantId}</TableCell>
                <TableCell className="tableCell">{finance.type}</TableCell>
                <TableCell className="tableCell">{finance.totalCalculatedValue}</TableCell>
                <TableCell className="tableCell">{formatDate(finance.createdAt)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 20, 30, { label: 'All', value: -1 }]}
          component="div"
          count={finances.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};

export default Financedata;
