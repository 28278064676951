import {
  DarkModeOutlined,
  FullscreenExitOutlined,
  ListOutlined,
  NotificationAddOutlined,
} from '@mui/icons-material';
import './navbar.scss';
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
const Navbar = () => {
  return (
    <div className="navbar">
      <div className="wrapper">
        <div className="search">

        </div>
        <div className="items">
          <div className="item">
            <DarkModeOutlined className="icon" />
          </div>
          <div className="item">
            <NotificationAddOutlined className="icon" />
            <div className="counter">1</div>
          </div>
          <div className="item">
            <FullscreenExitOutlined className="icon" />
          </div>
          <div className="item">
            <ListOutlined className="icon" />
          </div>
          <div className="item">
            <AccountCircleIcon className="icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
