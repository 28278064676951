import './order.scss'
import Sidebar from '../../components/sidebar/Sidebar'
import Navbar from '../../components/navbar/Navbar'
import Orderdata from '../../components/orderdata/Orderdata'

const Order = () => {
  return (
   <div className="orders">
    <Sidebar />
    <div className="listOrders">
        <Navbar />
        <Orderdata />
    </div>
   </div>
  )
}

export default Order