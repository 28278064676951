import './table.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const List = () => {
  const rows = [
    {
      id: 'MID12220001',
      merchant: 'Jhone Sdn Bhd',
      img: 'https://m.media-amazon.com/images/I/81bc8mA3nKL._AC_UY327_FMwebp_QL65_.jpg',
      email: 'sales@mail.com',
      address: 'Jalan 42/A, PJ, Selangor',
      phone: '+6012369811',
      date: '1 March 2023',
      status: 'Pending',
    },
    {
      id: 'MID12220002',
      merchant: 'Jhone Sdn Bhd',
      img: 'https://m.media-amazon.com/images/I/81bc8mA3nKL._AC_UY327_FMwebp_QL65_.jpg',
      email: 'sales@mail.com',
      address: 'Jalan 42/A, PJ, Selangor',
      phone: '+6012369811',
      date: '1 March 2023',
      status: 'Approved',
    },
    {
      id: 'MID12220003',
      merchant: 'Jhone Sdn Bhd',
      img: 'https://m.media-amazon.com/images/I/81bc8mA3nKL._AC_UY327_FMwebp_QL65_.jpg',
      email: 'sales@mail.com',
      address: 'Jalan 42/A, PJ, Selangor',
      phone: '+6012369811',
      date: '1 March 2023',
      status: 'Pending',
    },
    {
      id: 'MID12220004',
      merchant: 'Jhone Sdn Bhd',
      img: 'https://m.media-amazon.com/images/I/81bc8mA3nKL._AC_UY327_FMwebp_QL65_.jpg',
      email: 'sales@mail.com',
      address: 'Jalan 42/A, PJ, Selangor',
      phone: '+6012369811',
      date: '1 March 2023',
      status: 'Approved',
    },
    {
      id: 'MID12220005',
      merchant: 'Jhone Sdn Bhd',
      img: 'https://m.media-amazon.com/images/I/81bc8mA3nKL._AC_UY327_FMwebp_QL65_.jpg',
      email: 'sales@mail.com',
      address: 'Jalan 42/A, PJ, Selangor',
      phone: '+6012369811',
      date: '1 March 2023',
      status: 'Pending',
    },
  ];

  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell"> MID</TableCell>
            <TableCell className="tableCell">Merchant</TableCell>
            <TableCell className="tableCell">Email</TableCell>
            <TableCell className="tableCell">Address</TableCell>
            <TableCell className="tableCell">Phone</TableCell>
            <TableCell className="tableCell">Date</TableCell>
            <TableCell C>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell className="tableCell">{row.merchant}</TableCell>
              <TableCell className="tableCell">{row.email}</TableCell>
              <TableCell className="tableCell">{row.address}</TableCell>
              <TableCell className="tableCell">{row.phone}</TableCell>
              <TableCell className="tableCell">{row.date}</TableCell>
              <TableCell className="tableCell">
                <span className={`status ${row.status}`}>{row.status}</span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default List;
