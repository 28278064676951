import React, { useState, useEffect } from 'react';
import './addorder.scss';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const Addorder = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        // Retrieve the token from localStorage
        const storedToken = localStorage.getItem('accessToken');

        const response = await fetch('${process.env.REACT_APP_API_URL}/orders', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            token: `Bearer ${storedToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setRows(data);
        setLoading(false);

        // Fetch shop details for each order
        console.log('orders',data)
        updatetableTitles(data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    // Call the fetch function
    fetchOrders();
  }, []); // Empty dependency array to run the effect only once

  const fetchtableTitle = async (tableId) => {
    try {
      const storedToken = localStorage.getItem('accessToken');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/tables/find/${tableId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token: `Bearer ${storedToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const shopData = await response.json();
      return shopData.title;
    } catch (error) {
      console.error('Error fetching shop data:', error);
      return 'N/A'; // Return a default value or handle the error as needed
    }
  };

  const updatetableTitles = async (orderData) => {
    try {
      const tableTitlePromises = orderData.map(async (order) => {
        const title = await fetchtableTitle(order.tableId);
        return { ...order, tableTitle: title };
      });

      const ordersWithTitles = await Promise.all(tableTitlePromises);
      setRows(ordersWithTitles);
    } catch (error) {
      console.error('Error updating shop titles:', error);
    }
  };

  return (
    <TableContainer component={Paper} className="reporttable">
      {loading ? (
        <p>Loading...</p>
      ) : (
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableCell">Order ID</TableCell>
              <TableCell className="tableCell">Table ID</TableCell>
          
              <TableCell className="tableCell">Amount</TableCell>
              <TableCell className="tableCell">Created At</TableCell>
              <TableCell className="tableCell">Status</TableCell>
              <TableCell className="tableCell">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row._id}</TableCell>
                <TableCell className="tableCell">{row.tableId}</TableCell>
           
                <TableCell className="tableCell">{row.amount}</TableCell>
                <TableCell className="tableCell">{row.createdAt}</TableCell>
                <TableCell className="tableCell">
                  <span className={`status ${row.status ? 'pending' : 'paid'}`}>
                    {row.status ? 'pending' : 'Paid'}
                  </span>
                </TableCell>
                <TableCell className="tableCell">
                  <MoreVertIcon />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default Addorder;
