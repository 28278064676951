import React, { useState, useEffect } from 'react';
import './queuedata.scss';
import '../topbar/topbar.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';

const Closedata = () => {
  const [opencashs, setOpencashs] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchOpenCashs = async () => {
      try {
        const response = await fetch(`${apiUrl}/closecashs`);
        if (!response.ok) {
          throw new Error('Failed to fetch open cash data');
        }
        const data = await response.json();
        setOpencashs(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchOpenCashs();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <div className="navbar">
        <div className="wrapper">
          <div className="items">
            <div className="item">
              <Link to="/closebalances/addClose" style={{ textDecoration: 'none' }}>
                <AddIcon className="icon" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <TableContainer component={Paper} className="reporttable">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableCell">Merchant ID</TableCell>
              <TableCell className="tableCell">Closing Amount</TableCell>
              <TableCell className="tableCell">Total Calculated Value</TableCell>
              <TableCell className="tableCell">Notes</TableCell>
              <TableCell className="tableCell">Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {opencashs.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((opencash) => (
              <TableRow key={opencash._id}>
                <TableCell>{opencash.merchantId}</TableCell>
                <TableCell className="tableCell">{opencash.closingAmount}</TableCell>
                <TableCell className="tableCell">{opencash.totalCalculatedValue}</TableCell>
                <TableCell className="tableCell">{opencash.notes}</TableCell>
                <TableCell className="tableCell">{opencash.createdAt}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={opencashs.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
};

export default Closedata;
