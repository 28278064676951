import './report.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
// import Userdata from '../../components/userdata/Userdata';
import Topbar from '../../components/topbar/Topbar';
import Reportdata from '../../components/reportdata/Reportdata';
const Report = () => {
  return (
    <div className="report">
      <Sidebar />
      <div className="reportContainer">
        <Navbar />
        <Topbar />
        <Reportdata />
      </div>
    </div>
  );
};

export default Report;
