import React, { useState, useEffect } from 'react';
import './addqueue.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';

const AddOpen = () => {
  const [formData, setFormData] = useState({
    openingAmount: 650,
    notes: "Cash opening for the day",
    paymentMethods: [
      { name: "Coins", denomination: 0.05, quantity: 0 },
      { name: "Coins", denomination: 0.1, quantity: 0 },
      { name: "Coins", denomination: 0.2, quantity: 0 },
      { name: "Coins", denomination: 0.5, quantity: 0 },
      { name: "Notes", denomination: 1, quantity: 0 },
      { name: "Notes", denomination: 5, quantity: 0 },
      { name: "Notes", denomination: 10, quantity: 0 },
      { name: "Notes", denomination: 20, quantity: 0 },
      { name: "Notes", denomination: 50, quantity: 0 },
      { name: "Notes", denomination: 100, quantity: 0 },
      { name: "eWallets", denomination: 0, quantity: 0 },
      { name: "Debit-Credit Card", denomination: 0, quantity: 0 }
    ],
    totalCalculatedValue: 0
  });

  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // Calculate totalCalculatedValue when formData changes
    let totalCalculatedValue = 0;
    formData.paymentMethods.forEach(method => {
      totalCalculatedValue += method.denomination * method.quantity;
    });
    setFormData(prevState => ({
      ...prevState,
      totalCalculatedValue: totalCalculatedValue
    }));
  }, [formData]);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const updatedPaymentMethods = [...formData.paymentMethods];
    updatedPaymentMethods[index][name] = value;
    setFormData({
      ...formData,
      paymentMethods: updatedPaymentMethods
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const storedToken = localStorage.getItem('accessToken');
      const response = await fetch(`${apiUrl}/opencashs`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const responseData = await response.json();
        setSuccessMessage('OpenCash created successfully!');
        setErrorMessage(''); // Clear any previous error message
        navigate('/openbalances');
        console.log('OpenCash created successfully:', responseData);
      } else {
        const errorResponse = await response.json();
        setErrorMessage(`Error creating OpenCash: ${errorResponse.message}`);
        setSuccessMessage(''); // Clear any previous success message
        console.error('Error creating OpenCash:', errorResponse.message);
      }
    } catch (error) {
      setErrorMessage(`Error: ${error.message}`);
      setSuccessMessage(''); // Clear any previous success message
      console.error('Error:', error);
    }
  };

  return (
    <div className="addqueue">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>Add Open Balance</h1><br/>
          <p>Total Calculated Value: {formData.totalCalculatedValue}</p>
        </div>
        <div className="bottom">
          <div className="right">
            {successMessage && <p className="success-message">{successMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <form onSubmit={handleSubmit}>
              {formData.paymentMethods.map((method, index) => (
                <div key={index} className="formInput">
                  <label>{method.name} - {method.denomination}</label>
                  <input
                    type="number"
                    name="quantity"
                    value={method.quantity}
                    onChange={(e) => handleChange(e, index)}
                  />
                </div>
              ))}
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddOpen;
