import './queue.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import Closedata from '../../components/closedata/Closedata';
const Close = () => {
  return (
    <div className="report">
      <Sidebar />
      <div className="reportContainer">
        <Navbar />
        {/* <Topbar /> */}
        <Closedata />
      </div>
    </div>
  );
};

export default Close;
