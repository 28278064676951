import './queue.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import Queuedata from '../../components/queuedata/Queuedata';
const Queue = () => {
  return (
    <div className="report">
      <Sidebar />
      <div className="reportContainer">
        <Navbar />
        {/* <Topbar /> */}
        <Queuedata />
      </div>
    </div>
  );
};

export default Queue;
