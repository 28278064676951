// AuthRoute.js
import React from 'react';
import { Route, Navigate } from 'react-router-dom';

const AuthRoute = ({ element }) => {
  // Check for accessToken in localStorage
  const accessToken = localStorage.getItem('accessToken');

  // If accessToken is present, render the protected route
  // Otherwise, redirect to the login page
  return accessToken ? element : <Navigate to="/login" />;
};

export default AuthRoute;
