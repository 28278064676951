import React from 'react';
import './sidebar.scss';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import CategoryIcon from '@mui/icons-material/Category';
import TableRestaurantIcon from '@mui/icons-material/TableRestaurant';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DescriptionIcon from '@mui/icons-material/Description';
import GroupIcon from '@mui/icons-material/Group';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link,useLocation  } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import GroupsIcon from '@mui/icons-material/Groups';
import CurtainsIcon from '@mui/icons-material/Curtains';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
const Sidebar = () => {
  // State for managing the dropdown menu
  const [anchorEl, setAnchorEl] = React.useState(null);
  const location = useLocation();

  // Open the dropdown menu
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Close the dropdown menu
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    // Clear accessToken from localStorage
    localStorage.removeItem('accessToken');
  }
  // const toCamelCase = (str) => {
  //   return str.replace(/\s(.)/g, function (match) {
  //     return match.toUpperCase();
  //   }).replace(/\s/g, '').replace(/^(.)/, function (match) {
  //     return match.toLowerCase();
  //   });
  // };
  return (
    <div className="sidebar">
      <div className="top">
        <span className="logo">
        TWOTWO TECH
        </span>
      </div>
      <div className="center">
        <ul>
          <li className={location.pathname === '/' ? 'active' : ''}>
            <DashboardIcon className="icon" />
            <Link to="/" style={{ textDecoration: 'none' }}>
              <span> Dashboard</span>
            </Link>
          </li>
          <li className={location.pathname === '/foods' ? 'active' : ''}>
            <ProductionQuantityLimitsIcon className="icon" />
            <Link to="/foods" style={{ textDecoration: 'none' }}>
              <span> Foods</span>
            </Link>
          </li>
          <li className={location.pathname === '/categories' ? 'active' : ''}>
            <CategoryIcon className="icon" />
            <Link to="/categories" style={{ textDecoration: 'none' }}>
              <span> Categories</span>
            </Link>
          </li>
          {/* Tables dropdown */}
          <li className={location.pathname === '/tables' ? 'active' : ''}>
            <Button onClick={handleMenuOpen} style={{ textDecoration: 'none'}}>
              <TableRestaurantIcon className="icon" />
              <span> Tables</span>
              <KeyboardArrowRightIcon />
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
              <MenuItem>
                <Link to="/tables" style={{ textDecoration: 'none' }}>
                  <span> Table view</span>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to="/tables/addtable" style={{ textDecoration: 'none' }}>
                  <span> Add Table</span>
                </Link>
              </MenuItem>
              <MenuItem>
                <Link to="/tables/tablelist" style={{ textDecoration: 'none' }}>
                  <span>Table list </span>
                </Link>
              </MenuItem>
            </Menu>
          </li>
          {/* End of Tables dropdown */}
          <li className={location.pathname === '/orders' ? 'active' : ''}>
            <ListAltIcon className="icon" />
            <Link to="/orders" style={{ textDecoration: 'none' }}>
              <span> Orders</span>
            </Link>
          </li>
          {/* <li>
            <DescriptionIcon className="icon" />
            <Link to="/reports" style={{ textDecoration: 'none' }}>
              <span> Reports</span>
            </Link>
          </li> */}
          {/* <li>
            <CurtainsIcon className="icon" />
            <Link to="/openbalances" style={{ textDecoration: 'none' }}>
              <span> Open Balance</span>
            </Link>
          </li>
          <li>
            <CloseFullscreenIcon className="icon" />
            <Link to="/closebalances" style={{ textDecoration: 'none' }}>
              <span> Close Balance</span>
            </Link>
          </li> */}
          <li className={location.pathname === '/finances' ? 'active' : ''}>
            <CardMembershipIcon className="icon" />
            <Link to="/finances" style={{ textDecoration: 'none' }}>
              <span> Finance</span>
            </Link>
          </li>
          <li className={location.pathname === '/printers' ? 'active' : ''}>
            <LocalPrintshopIcon className="icon" />
            <Link to="/printers" style={{ textDecoration: 'none' }}>
              <span> Printers</span>
            </Link>
          </li>
          <li className={location.pathname === '/queues' ? 'active' : ''}>
            <GroupsIcon className="icon" />
            <Link to="/queues" style={{ textDecoration: 'none' }}>
              <span> Queue</span>
            </Link>
          </li>
          <li className={location.pathname === '/users' ? 'active' : ''}>
            <GroupIcon className="icon" />
            <Link to="/users" style={{ textDecoration: 'none' }}>
              <span> Users</span>
            </Link>
          </li>
          {/* <li>
            <TuneIcon className="icon" />
            <span> Roles</span>
          </li> */}
          <li>
            <LogoutIcon className="icon" />
            <Link to="/login" style={{ textDecoration: 'none' }}>
              <span  onClick={handleLogout}> Logout</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
