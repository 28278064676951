import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import DownloadIcon from '@mui/icons-material/Download';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Dialog from '@mui/material/Dialog';
import QRCode from 'react-qr-code';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import html2canvas from 'html2canvas';

const Tablelistview = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10); // Default rows per page set to 10
  const [rows, setRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [qrPopupOpen, setQrPopupOpen] = useState(false);
  const [qrCodeUrl, setQRCodeUrl] = useState('');
  const [tableNo, setTableNo] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const storedToken = localStorage.getItem('accessToken');
    fetch(`${apiUrl}/tables`
      , {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
      })
      .then((response) => response.json())
      .then((data) => {
        console.log('Fetched data:', data);
        setRows(data);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleClick = (event, rowId) => {
    setAnchorEl(event.currentTarget);
    setSelectedRowId(rowId);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRowId(null);
  };

  const openQrPopup = () => {
    const currentRowId = selectedRowId; // Save the current selectedRowId
    setQrPopupOpen(true);
    handleClose(); // Close the menu when opening the popup

    // Fetch the QR code data using the accessToken
    const selectedTable = rows.find((table) => table._id === currentRowId);
    const accessToken = selectedTable.accessToken;

    fetch(`${apiUrl}/tables/qrurl/${currentRowId}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((qrCodeData) => {
        setQRCodeUrl(qrCodeData.qrCodeUrl);
        setTableNo(qrCodeData.tableNo); // Assuming you have state for tableNo
      })
      .catch((error) => console.error('Error fetching QR code data:', error));
  };

  const closeQrPopup = () => {
    setQrPopupOpen(false);
    setQRCodeUrl(''); // Clear the QR code URL when closing the popup
  };

  
  // const downloadQRCode = async () => {
  //   try {
  //     // Capture the dialog content using html2canvas
  //     const dialogContent = document.querySelector('.popupContent');
  //     const canvas = await html2canvas(dialogContent);
  
  //     // Convert the canvas to a data URL
  //     const imageDataURL = canvas.toDataURL('image/png');
  
  //     // Create a temporary anchor element
  //     const link = document.createElement('a');
  //     link.href = imageDataURL;
  //     link.download = `qrcode_table_${tableNo}_screenshot.png`; // Adjust the filename as needed
  //     document.body.appendChild(link);
  
  //     // Trigger the download
  //     link.click();
  
  //     // Remove the temporary anchor element
  //     document.body.removeChild(link);
  //   } catch (error) {
  //     console.error('Error generating QR code screenshot:', error);
  //   }
  // };
  
  const downloadQRCode = async () => {
    try {
      // Capture the dialog content using html2canvas
      const dialogContent = document.querySelector('.popupContent');
      const canvas = await html2canvas(dialogContent, {
        ignoreElements: (element) => {
          // Ignore the elements with the "ignore" class
          return element.classList.contains('popupActions') || element.classList.contains('tableInfo');
        }
      });
  
      // Convert the canvas to a data URL
      const imageDataURL = canvas.toDataURL('image/png');
  
      // Create a temporary anchor element
      const link = document.createElement('a');
      link.href = imageDataURL;
      link.download = `qrcode_table_${tableNo}_screenshot.png`; // Adjust the filename as needed
      document.body.appendChild(link);
  
      // Trigger the download
      link.click();
  
      // Remove the temporary anchor element
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error generating QR code screenshot:', error);
    }
  };
  
  return (
    <div>
      <div className="navbar">
        <div className="wrapper">
          <div className="search">
            <input type="text" placeholder="Search..." />
            <SearchOutlinedIcon className="icon" />
          </div>
          <div className="items">
            <div className="item">
              <DownloadIcon className="icon" />
            </div>
          </div>
        </div>
      </div>
      <TableContainer component={Paper} className="producttable">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableCell">ID</TableCell>
              <TableCell className="tableCell">Table No</TableCell>
           
              <TableCell className="tableCell">Created At</TableCell>
              <TableCell className="tableCell">Updated At</TableCell>
              <TableCell className="tableCell">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row) => (
              <TableRow key={row._id}>
                <TableCell>{row._id}</TableCell>
                <TableCell>{row.tableno}</TableCell>
              
                <TableCell>{new Date(row.createdAt).toLocaleDateString()}</TableCell>
                <TableCell>{new Date(row.updatedAt).toLocaleDateString()}</TableCell>
                <TableCell className="tableCell action">
                  <MoreVertIcon onClick={(event) => handleClick(event, row._id)} />
                  <Menu
                    id={`simple-menu-${row._id}`}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl && selectedRowId === row._id)}
                    onClose={handleClose}
                  >
                    <MenuItem onClick={openQrPopup}>Generate QR</MenuItem>
                    <MenuItem component={Link} to={`/orders/addorder/${row._id}`}>
                      Create Order
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]} // Options for rows per page
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      {/* <Dialog open={qrPopupOpen} onClose={closeQrPopup}>
        <div className="popupContent">
          <p className="tableInfo">Table No: {tableNo}</p>
          {qrCodeUrl && <QRCode value={qrCodeUrl} />}
          <IconButton onClick={closeQrPopup} color="primary">
            <CloseIcon />
          </IconButton>
        </div>
      </Dialog> */}
      <Dialog open={qrPopupOpen} onClose={closeQrPopup}>
        <div className="popupContent">
          <p className="tableInfo">Table No: {tableNo}</p>
          {qrCodeUrl && <QRCode value={qrCodeUrl} />}
          <div className="popupActions">
            <IconButton onClick={closeQrPopup} color="primary">
              <CloseIcon />
            </IconButton>
            <IconButton onClick={downloadQRCode} color="primary">
              <DownloadIcon />
            </IconButton>
          </div>
        </div>
      </Dialog>

    </div>
  );
};

export default Tablelistview;
