// import Navbar from '../../components/navbar/Navbar';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
// import Widget from '../../components/widget/Widget';
import Cart from '../../components/cart/Cart.jsx';
import './tablelist.scss';

import Tablelistview from '../../components/tablecard/Tablelistview.jsx';

const Tablelist = () => {
  return (
    <div className="tablelist">
      <Sidebar />
      <div className="tablelistContainer">
        <Navbar />
          <Tablelistview  />

      </div>
    </div>
  );

};

export default Tablelist;
