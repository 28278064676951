import React, { useState } from 'react';
import './addqueue.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';

const AddQueue = () => {
  const [formData, setFormData] = useState({
    
  });
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      // Parse the num_customers value to an integer
      const numCustomers = parseInt(formData.num_customers);
  
      const apiData = {
        num_customers: numCustomers, // Send the parsed integer value
      };
      const storedToken = localStorage.getItem('accessToken');
      const response = await fetch(`${apiUrl}/queues`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify(apiData),
      });
  
      if (response.ok) {
        const responseData = await response.json();
        setSuccessMessage('Queue created successfully!');
        setErrorMessage(''); // Clear any previous error message
        navigate('/queues');
        console.log('Queue created successfully:', responseData);
      } else {
        const errorResponse = await response.json();
        setErrorMessage(`Error creating queue: ${errorResponse.message}`);
        setSuccessMessage(''); // Clear any previous success message
        console.error('Error creating queue:', errorResponse.message);
      }
    } catch (error) {
      setErrorMessage(`Error: ${error.message}`);
      setSuccessMessage(''); // Clear any previous success message
      console.error('Error:', error);
    }
  };
  

  return (
    <div className="addqueue">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>Add New Queue</h1>
        </div>
        <div className="bottom">
          <div className="right">
            {successMessage && <p className="success-message">{successMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <form onSubmit={handleSubmit}>
              <div className="formInput">
                <label>Number of Customers</label>
                <input type="number" name="num_customers" value={formData.num_customers} onChange={handleChange} />
              </div>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddQueue;
