// App.js
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/home/Home';
import Login from './pages/login/Login';
import Register from './pages/register/Register';
import List from './pages/list/List';
import Single from './pages/single/Single';
import New from './pages/new/New';
import Edit from './pages/edit/Edit';
import Report from './pages/report/Report';
import User from './pages/user/User';

import Product from './pages/product/Product';
import Category from './pages/category/Category';
import Table from './pages/table/Table';
import Addtable from './pages/table/Addtable';
import Order from './pages/order/Order';
import Tablelist from './pages/table/Tablelist';
// import QRCodePage from './pages/table/QRCodePage';
// import QRCodePopup from './pages/table/QRCodePopup';
import ProductEdit from './components/productdata/ProductEdit';

import AuthRoute from './AuthRoute'; // Import the AuthRoute component
import Addorder from './components/orderdata/Addorder';
import Printer from './pages/printer/Printer';
import Queue from './pages/queue/Queue';
import AddQueue from './pages/queue/AddQueue';
import AddPrinter from './pages/printer/AddPrinter';
import Open from './pages/open/Open';
import AddOpen from './pages/open/AddOpen';
import Close from './pages/close/Close';
import AddClose from './pages/close/AddClose';
import AddFinance from './pages/finance/AddFinance';
import Finance from './pages/finance/Finance';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AuthRoute element={<Home />} />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />

          {/* Protected Routes */}
          <Route path="users/*" element={<AuthRoute element={<User />} />} />
          <Route path="foods/*" element={<AuthRoute element={<Product />} />} />
          <Route path="foods/new" element={<AuthRoute element={<New />} />} />
          <Route path="foods/edit/:foodId" element={<AuthRoute element={<Edit />} />} />
          <Route path="tables/*" element={<AuthRoute element={<Table />} />} />
          <Route path="orders/*" element={<AuthRoute element={<Order />} />} />
          <Route path="categories/*" element={<AuthRoute element={<Category />} />} />
          <Route path="orders/addorder" element={<AuthRoute element={<Addorder />} />} />
          <Route path="reports/*" element={<AuthRoute element={<Report />} />} />
          <Route path="openbalances/*" element={<AuthRoute element={<Open />} />} />
          <Route path="openbalances/addOpen" element={<AuthRoute element={<AddOpen />} />} />
          <Route path="closebalances/*" element={<AuthRoute element={<Close />} />} />
          <Route path="closebalances/addClose" element={<AuthRoute element={<AddClose />} />} />
          <Route path="finances/*" element={<AuthRoute element={<Finance />} />} />
          <Route path="finances/addFinance" element={<AuthRoute element={<AddFinance />} />} />
          <Route path="printers/*" element={<AuthRoute element={<Printer />} />} />
          <Route path="printers/addPrinter" element={<AuthRoute element={<AddPrinter />} />} />
          <Route path="queues/*" element={<AuthRoute element={<Queue />} />} />
          <Route path="queues/addQueue" element={<AuthRoute element={<AddQueue />} />} />
          {/* ... (similar protected routes for other sections) */}

          {/* Other unprotected routes */}
          <Route path="tables/:tableId" element={<Single />} />
          {/* <Route path="/tables/addtable" element={<Addtable />} /> */}
          <Route path="tables/addtable" element={<AuthRoute element={<Addtable />} />} />
          <Route path="tables/tablelist" element={<AuthRoute element={<Tablelist />} />} />
          {/* <Route path="/tables/qrcodeurl:id" element={<AuthRoute element={<QRCodePage />} />} /> */}

          <Route path="/tables/tablelist" element={<Tablelist />} />
          {/* <Route path="/tables/qrcodeurl/:id" element={<QRCodePopup />} /> */}
          {/* <Route path="/tables/qrcodeurl/:id" element={<QRCodePage />} /> */}
          {/* ... (similar unprotected routes for other sections) */}
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
