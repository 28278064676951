import './categorydata.scss';
// import React, { useState } from 'react';

import '../topbar/topbar.scss';
// import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
// import { Link } from 'react-router-dom';
// import AddIcon from '@mui/icons-material/Add';
// import DownloadIcon from '@mui/icons-material/Download';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const Categorydata = () => {
  const rows = [
    {
      id: 'CAT001',
      category: 'Main course',

      status: 'Approved',
      commission: '0.20',
    },
    {
      id: 'CAT002',
      category: 'Side Dish',

      status: 'Approved',
      commission: '0.10',
    },
    {
      id: 'CAT003',
      category: 'Snacks',

      status: 'Approved',
      commission: '0.10',
    },
    {
      id: 'CAT004',
      category: 'Dessert ',

      status: 'Approved',
      commission: '0.10',
    },
    {
      id: 'CAT005',
      category: 'Appetizer ',

      status: 'Approved',
      commission: '0.10',
    },
    {
      id: 'CAT006',
      category: 'Alcohol',

      status: 'Approved',
      commission: '0.10',
    },
    {
      id: 'CAT007',
      category: 'Drinks ',

      status: 'Approved',
      commission: '0.10',
    }
  ];

  return (
    <TableContainer component={Paper} className="reporttable">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell"> ID</TableCell>
            <TableCell className="tableCell">Category</TableCell>
            <TableCell className="tableCell">Commission (RM)</TableCell>
            <TableCell className="tableCell">Status</TableCell>
            <TableCell className="tableCell">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.id}</TableCell>
              <TableCell className="tableCell">{row.category}</TableCell>

              <TableCell className="tableCell">{row.commission}</TableCell>

              <TableCell className="tableCell">
                <span className={`status ${row.status}`}>{row.status}</span>
              </TableCell>
              <TableCell className="tableCell">
                <MoreVertIcon />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Categorydata;
