import React, { useState } from 'react';
import './product.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';

import ProductEdit from '../../components/productdata/ProductEdit';
import Fooddata from '../../components/productdata/Fooddata';
const Product = () => {
//   return (
//     <div className="product">
//       <Sidebar />
//       <div className="productContainer">
//         <Navbar />
//         <Productdata />
//       </div>
//     </div>
//   );
// };
const [selectedProduct, setSelectedProduct] = useState(null);

const handleProductSelect = (productId) => {
  setSelectedProduct(productId);
};

return (
  <div className="product">
    <Sidebar />
    <div className="productContainer">
      <Navbar />
      <div className="bills">
        <Fooddata onSelect={handleProductSelect} />  {/* Use ProductData instead of Tablecard */}
        {selectedProduct && <ProductEdit productId={selectedProduct} />}  {/* Use productId instead of tableId */}
      </div>
    </div>
  </div>
);
};

export default Product;
