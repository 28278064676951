import React, { useState, useEffect } from 'react';
import './queuedata.scss';
import '../topbar/topbar.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';

const Queuedata = () => {
  const [queues, setQueues] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchQueues = async () => {
      try {
        const storedToken = localStorage.getItem('accessToken');
        const response = await fetch(`${apiUrl}/queues`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch queues');
        }
        const data = await response.json();
        setQueues(data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchQueues();
  }, []);

  
  const handlePrintQueue = async (queueId) => {
    try {
      const response = await fetch(`${apiUrl}/printers/queue/${queueId}`, {
        method: 'POST',
      });
      console.log(response); // Move the console.log here
      if (!response.ok) {
        throw new Error('Failed to print queue');
      }
      // Optionally, you can handle success here, e.g., show a message
    } catch (error) {
      console.error(error);
      // Optionally, you can handle error here, e.g., show an error message
    }
  };

  return (
    <div>
      <div className="navbar">
        <div className="wrapper">
          <div className="items">
            <div className="item">
              <Link to="/queues/addQueue" style={{ textDecoration: 'none' }}>
                <AddIcon className="icon" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <TableContainer component={Paper} className="reporttable">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableCell">Queue Number</TableCell>
              <TableCell className="tableCell">Number of Customers</TableCell>
              <TableCell className="tableCell">Status</TableCell>
              <TableCell className="tableCell">Created At</TableCell>
              <TableCell className="tableCell">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {queues.map((queue) => (
              <TableRow key={queue._id}>
                <TableCell>{queue.queue_number}</TableCell>
                <TableCell className="tableCell">{queue.num_customers}</TableCell>
                <TableCell className="tableCell">{queue.status}</TableCell>
                <TableCell className="tableCell">{queue.created_at}</TableCell>
                <TableCell className="tableCell">
                  <IconButton
                    color="primary"
                    aria-label="print"
                    onClick={() => handlePrintQueue(queue._id)}
                  >
                    <PrintIcon />
                  </IconButton>
                  <IconButton color="secondary" aria-label="edit">
                    <ToggleOffIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Queuedata;
