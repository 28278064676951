import React, { useState } from 'react';
import './new.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from "@aws-sdk/client-s3";
import { useNavigate } from 'react-router-dom';

const New = () => {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [formData, setFormData] = useState({
    title: '',
    desc: '',
    category: '',
    price: '',
    requests: [{ id: 1, name: '', addOnPrice: '' }],
    schedulingEnabled: false,
    schedule: [
      { timeOfDay: 'Morning Breakfast', checked: false, startTime: '', endTime: '' },
      { timeOfDay: 'Afternoon Lunch', checked: false, startTime: '', endTime: '' },
      { timeOfDay: 'Night Dinner', checked: false, startTime: '', endTime: '' }
    ]
  });
  const categories = ['Main course', 'Side Dish', 'Snacks', 'Desserts', 'Appetizer', 'Alcohol', "Drinks"];
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  const addRequest = () => {
    const newRequest = {
      id: formData.requests.length + 1,
      name: '',
      addOnPrice: ''
    };
    setFormData({
      ...formData,
      requests: [...formData.requests, newRequest]
    });
  };

  const deleteRequest = (id) => {
    const updatedRequests = formData.requests.filter(request => request.id !== id);
    setFormData({
      ...formData,
      requests: updatedRequests
    });
  };

  const handleInputChange = (id, fieldName, value) => {
    const updatedRequests = formData.requests.map(request =>
      request.id === id ? { ...request, [fieldName]: value } : request
    );
    setFormData({
      ...formData,
      requests: updatedRequests
    });
  };

  const handleScheduleChange = (index, checked) => {
    const updatedSchedule = formData.schedule.map((item, i) =>
      i === index ? { ...item, checked } : item
    );
    setFormData({
      ...formData,
      schedule: updatedSchedule
    });
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreviewUrl(objectUrl);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (file) {
        await uploadToS3(file);
      }

      const apiData = {
        title: formData.title,
        desc: formData.desc,
        categories: [formData.category],
        price: formData.price,
        requests: formData.requests,
        schedulingEnabled: formData.schedulingEnabled,
        schedule: formData.schedule.filter(item => item.checked), // Include only checked schedules
        img: file ? file.name : '',
      };
      const storedToken = localStorage.getItem('accessToken');
      const response = await fetch(`${apiUrl}/foods`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify(apiData),
      });

      if (response.ok) {
        alert('Food created successfully');
        navigate('/foods');
      } else {
        console.error('Error creating food:', response.statusText);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  // Function to upload file to S3 (unchanged)
  const uploadToS3 = async (file) => {
    try {
      const target = { Bucket: "ttt-media", Key: `food/${file.name}`, Body: file };
      const creds = {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      };

      const parallelUploadS3 = new Upload({
        client: new S3Client({ region: process.env.REACT_APP_AWS_REGION, credentials: creds }),
        params: target,
      });

      parallelUploadS3.on("httpUploadProgress", (progress) => {
        console.log(progress);
      });

      await parallelUploadS3.done();

      setPreviewUrl(`https://ttt-media.s3.ap-southeast-1.amazonaws.com/food/${file.name}`);
      setUploadStatus('Upload successful');
    } catch (e) {
      setUploadStatus(`Upload failed: ${e.message}`);
      console.error(e);
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>Add New Food</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <span className="status-message">{uploadStatus}</span>
            <form onSubmit={handleSubmit}>
              {/* Form inputs for title, description, category, requests (unchanged) */}
              <div className="formInput">
                <label>Title</label>
                <input type="text" name="title" placeholder="Food title" value={formData.title} onChange={(e) => setFormData({ ...formData, title: e.target.value })} />
              </div>
              <div className="formInput">
                <label>Desc</label>
                <input type="text" name="desc" placeholder="Food Description" value={formData.desc} onChange={(e) => setFormData({ ...formData, desc: e.target.value })} />
              </div>
              <div className="formInput">
                <label>Category</label>
                <select name="category" value={formData.category} onChange={(e) => setFormData({ ...formData, category: e.target.value })}>
                  <option value="" disabled>Select a category</option>
                  {categories.map((category, index) => (
                    <option key={index} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                {formData.requests.map((request, index) => (
                  <div key={index}>
                    <input
                      type="text"
                      value={request.name}
                      onChange={(e) => handleInputChange(request.id, 'name', e.target.value)}
                      placeholder={`Request item ${index + 1}`}
                    />
                    <input
                      type="text"
                      value={request.addOnPrice}
                      onChange={(e) => handleInputChange(request.id, 'addOnPrice', e.target.value)}
                      placeholder={`RM ${index + 1}`}
                    />

                    <button onClick={() => deleteRequest(request.id)}>Delete</button>
                  </div>
                ))}
              </div>
              <button type="button" onClick={addRequest}>Add Request</button>

              <div className="formInput">
                <label>Price</label>
                <input type="text" name="price" placeholder="Price" value={formData.price} onChange={(e) => setFormData({ ...formData, price: e.target.value })} />
              </div>
              <div className="formInput">
                <label htmlFor="file">
                  Food Image <DriveFolderUploadIcon className="icon" />
                </label>
                <input type="file" id="file" style={{ display: 'none' }} onChange={handleFileChange} />
                {file && <img src={previewUrl} alt="Preview" style={{ maxWidth: '15%', marginTop: '1px' }} />}
              </div>
              {/* Scheduling section */}
              <div>
                <label>
                  <input
                    type="checkbox"
                    checked={formData.schedulingEnabled}
                    onChange={(e) => setFormData({ ...formData, schedulingEnabled: e.target.checked })}
                  />
                  Enable Scheduling
                </label>
              </div>
              {formData.schedulingEnabled && (
                <div>
                  {formData.schedule.map((item, index) => (
                    <div key={index}>
                      <label>
                        <input
                          type="checkbox"
                          checked={item.checked}
                          onChange={(e) => handleScheduleChange(index, e.target.checked)}
                        />
                        {item.timeOfDay}
                      </label>
                      {item.checked && (
                        <>
                          <div>
                            <label>Start Time</label>
                            <input
                              type="time"
                              value={item.startTime}
                              onChange={(e) => setFormData(prevState => ({
                                ...prevState,
                                schedule: prevState.schedule.map((scheduleItem, idx) =>
                                  idx === index ? { ...scheduleItem, startTime: e.target.value } : scheduleItem
                                )
                              }))}
                            />
                          </div>
                          <div>
                            <label>AM/PM</label>
                            <select
                              value={item.startAP}
                              onChange={(e) => setFormData(prevState => ({
                                ...prevState,
                                schedule: prevState.schedule.map((scheduleItem, idx) =>
                                  idx === index ? { ...scheduleItem, startAP: e.target.value } : scheduleItem
                                )
                              }))}
                            >
                              <option value="AM">AM</option>
                              <option value="PM">PM</option>
                            </select>
                          </div>
                          <div>
                            <label>End Time</label>
                            <input
                              type="time"
                              value={item.endTime}
                              onChange={(e) => setFormData(prevState => ({
                                ...prevState,
                                schedule: prevState.schedule.map((scheduleItem, idx) =>
                                  idx === index ? { ...scheduleItem, endTime: e.target.value } : scheduleItem
                                )
                              }))}
                            />
                          </div>
                          <div>
                            <label>AM/PM</label>
                            <select
                              value={item.endAP}
                              onChange={(e) => setFormData(prevState => ({
                                ...prevState,
                                schedule: prevState.schedule.map((scheduleItem, idx) =>
                                  idx === index ? { ...scheduleItem, endAP: e.target.value } : scheduleItem
                                )
                              }))}
                            >
                              <option value="AM">AM</option>
                              <option value="PM">PM</option>
                            </select>
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </div>
              )}

              <button type="submit">Submit</button>

              {/* Form inputs for price, file upload (unchanged) */}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default New;
