import React, { useState, useEffect } from 'react';
import './productdata.scss';
import '../topbar/topbar.scss';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { Link, useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import DownloadIcon from '@mui/icons-material/Download';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';


const Fooddata = () => {
  // State variables
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  
 
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFoodId, setselectedFoodId] = useState(null);
  const [foundFood, setFoundFood] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // New state for modal
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;

  // Fetch data from the API when the component mounts
  // useEffect(() => {
  //   fetch(`${apiUrl}/foods`)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log('Fetched data:', data);
  //       setRows(data);
  //     })
  //     .catch((error) => console.error('Error fetching data:', error));
  // }, []);
  useEffect(() => {
    const storedToken = localStorage.getItem('accessToken');
    fetch(`${apiUrl}/foods`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${storedToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Fetched data type:', typeof data); // Check the type of data returned
        console.log('Fetched data:', data);
        setRows(data);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);
  

  // Handlers for changing page and rows per page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleClick = (event, foodId) => {
    setAnchorEl(event.currentTarget);
    setselectedFoodId(foodId); // Assign the foodId directly to selectedFoodId
  };

  const handleClose = () => {
    setAnchorEl(null);
    setselectedFoodId(null);
  };
  const handleEdit = async (foodId) => {
    try {
      navigate(`/foods/edit/${foodId}`);
      handleClose();
    } catch (error) {
      console.error('Error navigating to edit page:', error);
    }
  };

  const handleDelete = async (foodId) => {
    const userConfirmed = window.confirm("Are you sure want to delete?");
    if (!userConfirmed) {
      return;
    }

    try {
      const storedToken = localStorage.getItem('accessToken');
      const response = await fetch(`${apiUrl}/foods/${foodId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify({ isDelete: true }),
      });

      const responseData = await response.json();
      console.log('Delete Response:', responseData);

      if (response.ok) {
        const updatedData = await fetch(`${apiUrl}/foods`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        }
        )
          .then((response) => response.json())
          .catch((error) => console.error('Error fetching data:', error));
        setRows(updatedData);
        alert("Food item deleted successfully!");
      } else {
        console.error('Failed to delete food item:', responseData);
        alert("Failed to delete food item. Please try again.");
      }
    } catch (error) {
      console.error('Error deleting food item:', error);
      alert("Error deleting food item. Please try again.");
    }

    handleClose();
  };

  // const handleFoodStatus = async (foodId) => {
  //   const userConfirmed = window.confirm("Are you sure you want to update?");
  //   if (!userConfirmed || !foodId) {
  //     return;
  //   }

  //   try {
  //     const storedToken = localStorage.getItem('accessToken');
  //     const response = await fetch(`${apiUrl}/foods/${foodId}`, {
  //       method: 'PUT',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         token: `Bearer ${storedToken}`,
  //       },
  //       body: JSON.stringify({ inStock: !foundFood.inStock }),
  //     });

  //     const responseData = await response.json();
  //     console.log('Status Update Response:', responseData);

  //     if (response.ok) {
  //       const updatedData = rows.map(row => row._id === foodId ? responseData : row);
  //       setRows(updatedData);
  //       alert("Food item status updated successfully!");
  //     } else {
  //       console.error('Failed to update food item status:', responseData);
  //       alert("Failed to update food item status. Please try again.");
  //     }
  //   } catch (error) {
  //     console.error('Error updating food item status:', error);
  //     alert("Error updating food item status. Please try again.");
  //   }

  //   handleClose();
  // };

  const handleFoodStatus = async (foodId) => {
    const userConfirmed = window.confirm("Are you sure you want to update?");
    if (!userConfirmed || !foodId) {
      return;
    }
  
    try {
      const storedToken = localStorage.getItem('accessToken');
      const foundFood = rows.find(row => row._id === foodId); // Find the food item
      const newInStockValue = !foundFood.inStock; // Toggle the inStock value
      const response = await fetch(`${apiUrl}/foods/${foodId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify({ inStock: newInStockValue }),
      });
  
      const responseData = await response.json();
      console.log('Status Update Response:', responseData);
  
      if (response.ok) {
        const updatedData = rows.map(row => row._id === foodId ? responseData : row);
        setRows(updatedData);
        alert("Food item status updated successfully!");
      } else {
        console.error('Failed to update food item status:', responseData);
        alert("Failed to update food item status. Please try again.");
      }
    } catch (error) {
      console.error('Error updating food item status:', error);
      alert("Error updating food item status. Please try again.");
    }
  
    handleClose();
  };
  
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <div>
      {/* Navbar */}
      <div className="navbar">
        <div className="wrapper">
          <div className="search">
            <input type="text" placeholder="Search..." />
            <SearchOutlinedIcon className="icon" />
          </div>
          <div className="items">
            <div className="item">
              <Link to="/foods/new" style={{ textDecoration: 'none' }}>
                <AddIcon className="icon" />
              </Link>
            </div>
            <div className="item">
              <DownloadIcon className="icon" />
            </div>
          </div>
        </div>
      </div>

      {/* Product Table */}
      <TableContainer component={Paper} className="producttable">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="tableCell">ID</TableCell>
              <TableCell className="tableCell">Image</TableCell>
              <TableCell className="tableCell">Title</TableCell>
              <TableCell className="tableCell">Categories</TableCell>
              <TableCell className="tableCell">Price</TableCell>
              <TableCell className="tableCell">Update At</TableCell>
              <TableCell className="tableCell">Status</TableCell>
              <TableCell className="tableCell">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row) => (
              <TableRow key={row._id}>
                <TableCell>{row._id}</TableCell>
                <TableCell>
                  <img src={row.img} alt="Product" style={{ width: '70px', height: '50px' }} />
                </TableCell>
                <TableCell className="tableCell">{row.title}</TableCell>
                <TableCell className="tableCell">{row.categories}</TableCell>
                <TableCell className="tableCell">RM{row.price.toFixed(2)}</TableCell>
                <TableCell className="tableCell">{formatDate(row.updatedAt)}</TableCell>
                <TableCell className="tableCell">
                  <span className={`inStock ${row.inStock ? 'in-stock' : 'out-of-stock'}`}>
                    {row.inStock ? 'In Stock' : 'Out of Stock'}
                  </span>
                </TableCell>
                <TableCell className="tableCell action">
                  <EditIcon
                    onClick={(event) => handleClick(event, row._id)}
                    className="edit-icon" // Apply the edit-icon class
                    style={{ cursor: 'pointer', marginRight: '5px' }}
                  />
                  <CheckCircleIcon
                    onClick={() => handleFoodStatus(row._id)}
                    className="status-icon" // Apply the status-icon class
                    style={{ cursor: 'pointer', marginRight: '5px' }}
                  />
                  <DeleteIcon
                    onClick={() => handleDelete(row._id)}
                    className="delete-icon" // Apply the delete-icon class
                    style={{ cursor: 'pointer' }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <TablePagination
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default Fooddata;
