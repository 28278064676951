import React, { useState } from 'react';
import './addprinter.scss';
import Navbar from '../../components/navbar/Navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { useNavigate } from 'react-router-dom';

const AddPrinter = () => {
  const [formData, setFormData] = useState({
    printerName: '',
    ipAddress: '',
    port: '',
    location: '',
    printerType: [],
  });
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const apiUrl = process.env.REACT_APP_API_URL;
  
  const handleChange = (e) => {
    const { name, value, checked } = e.target;
  
    if (name === 'printerType') {
      // Handle checkbox change
      if (checked) {
        setFormData({
          ...formData,
          printerType: value, // Set printerType as string
        });
      } else {
        setFormData({
          ...formData,
          printerType: '', // Reset printerType if unchecked
        });
      }
    } else {
      // Handle other input changes
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  // const handleChange = (e) => {
  //   const { name, value, checked } = e.target;

  //   if (name === 'printerType') {
  //     // Handle checkbox change
  //     if (checked) {
  //       setFormData({
  //         ...formData,
  //         [name]: [...formData.printerType, value],
  //       });
  //     } else {
  //       setFormData({
  //         ...formData,
  //         [name]: formData.printerType.filter((type) => type !== value),
  //       });
  //     }
  //   } else {
  //     // Handle other input changes
  //     setFormData({
  //       ...formData,
  //       [name]: value,
  //     });
  //   }
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   try {
  //     const storedToken = localStorage.getItem('accessToken');
  //     const response = await fetch(`${apiUrl}/printers`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${storedToken}`,
  //       },
  //       body: JSON.stringify(formData),
  //     });

  //     if (response.ok) {
  //       const responseData = await response.json();
  //       setSuccessMessage('Printer created successfully!');
  //       setErrorMessage(''); // Clear any previous error message
  //       navigate('/printers');
  //       console.log('Printer created successfully:', responseData);
  //     } else {
  //       const errorResponse = await response.json();
  //       setErrorMessage(`Error creating printer: ${errorResponse.message}`);
  //       setSuccessMessage(''); // Clear any previous success message
  //       console.error('Error creating printer:', errorResponse.message);
  //     }
  //   } catch (error) {
  //     setErrorMessage(`Error: ${error.message}`);
  //     setSuccessMessage(''); // Clear any previous success message
  //     console.error('Error:', error);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const storedToken = localStorage.getItem('accessToken');
      const requestData = {
        ...formData,
        printerType: formData.printerType, // Ensure printerType is included
      };
  
      const response = await fetch(`${apiUrl}/printers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${storedToken}`,
        },
        body: JSON.stringify(requestData),
      });
  
      if (response.ok) {
        const responseData = await response.json();
        setSuccessMessage('Printer created successfully!');
        setErrorMessage(''); // Clear any previous error message
        navigate('/printers');
        console.log('Printer created successfully:', responseData);
      } else {
        const errorResponse = await response.json();
        setErrorMessage(`Error creating printer: ${errorResponse.message}`);
        setSuccessMessage(''); // Clear any previous success message
        console.error('Error creating printer:', errorResponse.message);
      }
    } catch (error) {
      setErrorMessage(`Error: ${error.message}`);
      setSuccessMessage(''); // Clear any previous success message
      console.error('Error:', error);
    }
  };
  
  return (
    <div className="addprinter">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>Add New Printer</h1>
        </div>
        <div className="bottom">
          <div className="right">
            {successMessage && <p className="success-message">{successMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <form onSubmit={handleSubmit}>
              <div className="formInput">
                <label>Printer Name</label>
                <input type="text" name="printerName" value={formData.printerName} onChange={handleChange} />
              </div>
              <div className="formInput">
                <label>IP Address</label>
                <input type="text" name="ipAddress" value={formData.ipAddress} onChange={handleChange} />
              </div>
              <div className="formInput">
                <label>Port</label>
                <input type="number" name="port" value={formData.port} onChange={handleChange} />
              </div>
              <div className="formInput">
                <label>Location</label>
                <input type="text" name="location" value={formData.location} onChange={handleChange} />
              </div>
              <div className="formInput">
                <label>Printer Type</label>
                <div>
                  <label>
                    <input
                      type="checkbox"
                      name="printerType"
                      value="cashier"
                      checked={formData.printerType.includes('cashier')}
                      onChange={handleChange}
                    />
                    Cashier
                  </label>
                </div>
                <div>
                  <label>
                    <input
                      type="checkbox"
                      name="printerType"
                      value="kitchen"
                      checked={formData.printerType.includes('kitchen')}
                      onChange={handleChange}
                    />
                    Kitchen
                  </label>
                </div>
                <div>
                  <label>
                    <input
                      type="checkbox"
                      name="printerType"
                      value="bar"
                      checked={formData.printerType.includes('bar')}
                      onChange={handleChange}
                    />
                    Bar
                  </label>
                </div>
                <div>
                  <label>
                    <input
                      type="checkbox"
                      name="printerType"
                      value="queue"
                      checked={formData.printerType.includes('queue')}
                      onChange={handleChange}
                    />
                    Queue
                  </label>
                </div>
              </div>
              <button type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPrinter;
