import React, { useState, useEffect } from 'react';
import './orderdata.scss';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';

const Orderdata = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        // Retrieve the token from localStorage
        const storedToken = localStorage.getItem('accessToken');

        const response = await fetch(`${apiUrl}/orders`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${storedToken}`,
          },
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setRows(data);
        setLoading(false);

        // Fetch shop details for each order
        console.log('orders', data)
        updatetableTitles(data);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };

    // Call the fetch function
    fetchOrders();
  }, []); // Empty dependency array to run the effect only once

  // Handlers for changing page and rows per page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchtableTitle = async (tableId) => {
    try {
      const storedToken = localStorage.getItem('accessToken');
      const response = await fetch(`${apiUrl}/tables/find/${tableId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          token: `Bearer ${storedToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const shopData = await response.json();
      return shopData.title;
    } catch (error) {
      console.error('Error fetching shop data:', error);
      return 'N/A'; // Return a default value or handle the error as needed
    }
  };

  const updatetableTitles = async (orderData) => {
    try {
      const tableTitlePromises = orderData.map(async (order) => {
        const title = await fetchtableTitle(order.tableId);
        return { ...order, tableTitle: title };
      });

      const ordersWithTitles = await Promise.all(tableTitlePromises);
      setRows(ordersWithTitles);
    } catch (error) {
      console.error('Error updating shop titles:', error);
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
  return (
    <div>
      <TableContainer component={Paper} className="reporttable">
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell className="tableCell">Order ID</TableCell>

                  <TableCell className="tableCell">SST</TableCell>
                  <TableCell className="tableCell">Service Charges</TableCell>
                  <TableCell className="tableCell">Amount (RM)</TableCell>
                  <TableCell className="tableCell">Created At</TableCell>
                  <TableCell className="tableCell">Status</TableCell>
                  <TableCell className="tableCell">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : rows
                ).map((row) => (
                  <TableRow key={row._id}>
                    <TableCell>{row._id}</TableCell>

                    <TableCell className="tableCell">{row.sst.toFixed(2)}</TableCell>
                    <TableCell className="tableCell">{row.serviceCharge.toFixed(2)}</TableCell>
                    <TableCell className="tableCell">{row.grandTotal.toFixed(2)}</TableCell>
                    <TableCell className="tableCell">{formatDate(row.createdAt)}</TableCell>
                    <TableCell className="tableCell">
                      <span className={`status ${row.status ? 'pending' : 'paid'}`}>
                        {row.status ? 'pending' : 'Paid'}
                      </span>
                    </TableCell>
                    <TableCell className="tableCell">
                      <MoreVertIcon />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </TableContainer>
    </div>
  );
};

export default Orderdata;
